<template>
    <div class="page">
        <Part1 />
        <div class="mains">
            <div class="c-left">
                <div class="border"></div>
                <p class="fund">绩效管理</p>
                <p class="txt">
                    投资组合的绩效既来自于基金经理的决策，也取决于各市场的表现。绩效管理通过建立一套数学模型框架，既可以将组合的绝对收益或相对收益，分解到共同风险因子上，例如组合整体层面的利率与贝塔因子，也可以将基金经理创造的超额收益归因到每一个投资决策，以验证他们对各市场的表现和相互作用关系的观点。
                </p>
                <p class="txt">
                    智君科技提供包含因子分解和Brinson类型的归因分析，无论是针对单个基金，亦或公募和私募FoF，您可以针对不同投资策略和服务对象选择一个或多个不同的归因方法和报告模板。
                </p>
                <p class="solve">
                    <span>解决方案：</span>
                    <a><router-link to="/attributionperform">绩效归因<svg-icon icon-class="圆箭头" style="padding:0 3px"></svg-icon></router-link></a>
                    <a style="margin:0 18px"><router-link to="/product">公募基金投资<svg-icon icon-class="圆箭头" style="padding:0 3px"></svg-icon></router-link></a>
                </p>
            </div>
            <div class="c-right">
                <img src="../../assets/customer/绩效管理.png" alt="" style="width:474px;height:317px">
            </div>
        </div>
        <ApplyForm />
    </div>
    
</template>

<script>
import Part1 from './part1'
import ApplyForm from '../../components/ApplyForm'
export default {
    name:'PerformanceManage',
    components: { Part1,ApplyForm },
    data() {
        return {
            
        }
    }
}
</script>

<style lang="less" scoped>
.mains{
    width:1340px;
    height: 317px;
    margin:0 auto;
    // background:yellow;
    padding:79px 0 90px 0;
    .c-left{
        height:317px;
        // background:red;
        // width:15%;
        float:left;
        margin-left:5.4%;
        .border{
            width:130px;
            height:8px;
            background:#2E5082;
        }
        .fund{
            // width:308px;
            height:40px;
            font-size:32px;
            font-family:PingFangSC-Semibold,PingFang SC;
            font-weight:600;
            color:rgba(51,51,51,1);
            line-height:32px;
            margin:15px 0;
            text-align:left;
        }
        .txt{
            width:658px;
            // height:80px;
            // float:left;
            font-size:14px;
            font-family:PingFangSC-Regular,PingFang SC;
            font-weight:400;
            color:rgba(51,51,51,1);
            line-height:20px;
            text-indent: 28px;
            text-align:left;
            // margin-bottom:74px;
            
        }
        .mark{
                color:#2E5082;
                font-weight:bold;
            }
        .solve{
            // width:180px;
            height:20px;
            float:left;
            font-size:14px;
            font-family:PingFangSC-Regular,PingFang SC;
            // font-weight:400;
            color:rgba(51,51,51,1);
            line-height:20px;
            margin-top:19px;
            padding:0;
            a{
                text-decoration: none;
                color:#476CCB;
            }
        }

    }
    .c-right{
        width:474px;
        height:317px;
        float:right;
        margin-right:70px;
    }
    
}
</style>